import i18next, { t } from "i18next";
import moment from "moment";
import { initReactI18next } from "react-i18next";


import { localizeDefault } from "./localizeDefault";

const INTERPOLATION_DELIMITER = "**";

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? // @ts-ignore https://github.com/microsoft/TypeScript/issues/34933
      `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export type NestedKeyOfShallow<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

/**
 * Use with localizeShorthand, other template literals, or i18Next plurals -- Discouraged
 * @param input string
 * @param params key : value
 * @returns string
 */
export function localize(input: string, params?: { [key: string]: any }) {
  return t(input, params);
}

/**
 * Localization with intellisense enabled -- Preferred
 * @param input string
 * @param params key : value
 * @returns string
 */
export function typedLocalize<T extends typeof localizeDefault>(
  input: NestedKeyOf<T>,
  params?: { [key: string]: any }
) {
  return t(input as string, params);
}
export type LocalizeKey = NestedKeyOf<typeof localizeDefault>;

export function typedLocalizeKey<T extends typeof localizeDefault>(input: NestedKeyOf<T>) {
  return input as string;
}

export interface NestedTranslationOverrides<T> {
  [key: string]: T | NestedTranslationOverrides<T>;
}

i18next
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    // TODO: Declare array of ns (name spaces) bases on keyof typeof localizeDefault
    debug: false,
    interpolation: {
      prefix: INTERPOLATION_DELIMITER,
      suffix: INTERPOLATION_DELIMITER,
      escapeValue: false,
      format(value, format, lng) {
        if (moment.isDate(value)) {
          moment.locale(lng || "en");

          if (format) {
            if (format === "MM/DD/YYYY" && lng !== "en") {
              return moment(value).format("DD/MM/YYYY");
            }
            return moment(value).format(format);
          }
          return moment(value).format("MMMM DD, YYYY"); // If date format is incorrect, fallback is long date;
        }
        return value;
      },
    },
    resources: {
      en: {
        translation: {
          ...localizeDefault,
        },
      },
      es: {
        translation: {
          ...localizeDefault,
        },
      },
      fr: {
        translation: {
          ...localizeDefault,
        },
      },
      pt: {
        translation: {
          ...localizeDefault,
        },
      },
    },
  })
  .catch(error => {
    /** NO-OP */
  });

export { i18next as smartrri18next };
